body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.parent {
  /* display: flex; */
  /* flex-direction: column; */
  height:cover;
  /* height:110%; */
  /* width:100%;
  position:fixed; */
  background-image:url('./Img/BG.png');
  background-size:100% 100%;
}

.parent101 {
  /* display: flex; */
  /* flex-direction: column; */
  height:99.5vh;
  /* height:110%; */
  /* width:100%;
  position:fixed; */
  background-color:white;
  background-size:cover;
}

.parentClientSummary {
  /* display: flex; */
  /* flex-direction: column; */
  height:auto;
  /* height:110%; */
  /* width:100%;
  position:fixed; */
  background-image:url('./Img/BG.png');
  background-size:100% 100%;
}

.main {
  display:flex;
  flex-grow:1;
  padding-right:1%;

}

.content {
  flex-grow:1;
}
  
.child1 {
  display:flex;
  /* background-image:url('./Img/HEADER.png'); */
  /* background-size:100% 100%; */
  height:27%;
  
  /* border: 2px solid black; */
}
.child101 {
  display:flex;
  background-image:url('./Img/Contract_Search_BG1.png');
  background-size:100% 100%;
  height:32%;
  /* border: 2px solid black; */
}

.child7 {
  display:flex;
  height:24%;
  overflow-x: auto;
  padding-left: 7%;
  /* border: 2px solid black; */
}
.child {
  /* display: flex; */
  height:100%;
  /* width:14%; */
  
  /* border: 2px solid black; */
}

.child3 {
  /* display: flex; */
  height:10%;
  width:14%;
  padding-left:1%;
  
  
  /* border: 2px solid black; */
}

.child5{
  /* height:500px; */
  width:30%;
  padding-left:0.5%;
  overflow-y:auto;
  overflow-x: hidden;
}

.child2 {
  display:flex;
  width:100%;
  /* border: 2px solid black; */
}


.width101{
  /* width:100%; */
  margin-top:-1%;
  /* height:100% */
  
}

.header-img{
  width:100%;
  height:140%;
}

.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignLeft.MuiTableCell-sizeMedium{
  padding-top:0.5%;
  padding-bottom:0.7%;
}

.sidebar{
  width:153px;
  transition:width 0.3 ease;
  overflow:hidden;
  background-color:relative ;
  position:relative;
  cursor:pointer;
}





/*--------------------*/
.sidebarPanel {
  background-image: url("./Img/Contract\ Zoom\ LHC\ Tool_Side\ BG_02.png");
  background-size: 106%;
  height: calc(96vh - 0px);
  background-repeat: no-repeat;
  width: 200px;
  margin-top: -15px;
  transition: 0.7s;
}

.sidebarClosed {
  margin-left: -98px !important;
}

.mainPanel {
  /* height: 100vh; */
  /* margin-top: -100px; */
  /* width: calc(100vw - 200px); */
  /* height: calc(100vh - 170px); */
  transition: 0.7s;
  padding: 50px 50px !important;
  overflow: auto;
}



.menuIcon {
  color: #fff;
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin-left: 25px;
  margin-top: -5px;

}

.menuText {
  width: 80px;
  font-weight: bold;
  margin-top: -3%;
}

.menuTextMarginMenu{
  margin-top:4%;
}

.menuTextMarginOffshoreArchive{
  margin-top:-12%;
}

.menuTextMarginTemplate{
  margin-top:4%;
}

.colorWhite {
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
}

.colorOrange {
  color: #ff612b !important;
  border-radius: 5px;
  cursor: pointer;
}

.colorWhite:hover {
  color: #ff612b !important;
}
.colorWhite:focus {
  color: #fff;
}

.colorWhite:hover .menuIcon {
  color: #fff !important;
}

.marginTop70 {
  margin-top: 187px;
  margin-left: 3px;
}

.displayFlex {
  display: flex;
}

.width100 {
  width: 130px;
  padding: 11px 0px;
  text-align: right;
  margin-top: 26px;
  display: inline-flex;
  text-decoration: none;
}

.marginTop4 {
  margin-top: 4px !important;
  
}

.landingPage700{
  color:white;
  margin-left:-36%;
  margin-top:2%;
  font-weight:bold;
  font-size:106%;
  position: relative;
}

.clients{
  font-weight:normal;
  margin-top:-13.5%;
}

.landingPage1300{
  color:white;
  margin-left:7.5%;
  margin-top:2%;
  font-weight:bold;
  font-size:106%;
  position: relative;

}

.contracts{
  font-weight:normal;
  margin-top:-12%;
}

.landingPage500{
  color:white;
  margin-left:7%;
  margin-top:2%;
  font-Weight:bold;
  font-size:102%;
  position: relative;

}

.reviewedContracts{
  font-weight:normal;
  margin-top:-5%;
}

.Search-Home {
  background-image: url("./Img/1_bg.png");
  height: 100vh;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  /* width:100%; */
  background-position: cover;
  position: relative;

}

.LoginPage {
  background-image: url("./Img/Header_Login.png");
  height: 36vh;
  background-repeat: no-repeat;
  background-size: 100% 100%;

}

.MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedEnd{
  font-size: 80%;
  color:#002b80;
  font-weight: bold;
}

.SearchEngine{
  margin-top: 7%;
  color: white;
  margin-left:9%;
}

.SearchBelowContent{
  /* margin-top: 9.5%; */
  color: white;
  margin-left:9%;
}

.SearchInput{
  width: 550px;
  height: 40px;
  border-radius: 20px;
  /* margin-top: 12%; */
  font-size: 14px;
  padding: 18px;
  margin-left:9%;
}

.SearchIcon{
  margin-left: -2%;
  cursor: pointer;
}

.Text21k{
  color:#002b80;
  font-weight:bold;
  font-size:200%;
  margin-left:76%;
  margin-top:-20.5%;
}
 
.TextContracts{
  color:#002b80;
  font-weight:bold;
  font-size:60%;
  margin-left:-2%;
  margin-top:-4%;
}
 
.Text3839{
  color:#002b80;
  font-weight:bold;
  font-size:200%;
  margin-left:85%;
  margin-top:7%;
}
 
.TextClients{
  color:#002b80;
  font-weight:bold;
  font-size:60%;
  margin-left:-1%;
  margin-top:-7%;
}

.TextOffshoreRestrictionProcessing{
  color:#002b80;
  font-weight:bold;
  font-size:160%;
  margin-left:10%;
  /* margin-top:-6%; */
}

.OffshoreRestrictionProcessingBelowSentence{
  color:#002b80;
  font-weight:normal;
  font-size:60%;
}

.UploadContent{
  color:#002b80;
  font-weight:bold;
  font-size:100%;
  margin-left:14%;
  margin-top:-3%;
  cursor:pointer;
}

.AccessResults{
  color:#002b80;
  font-weight:bold;
  font-size:100%;
  margin-left:52%;
  margin-top:-3.5%;
  cursor: pointer;
}

.MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd{
  margin-top: -0.7%;
}

.appContainer{
  display:flex;
  flex-direction:'column';
  align-items: center;
  justify-content: center;
  gap: 10px;
  height:150%;
}

.cardContainer{
  display:flex;
  flex-direction: row;
  width:505px;
  height:80%;
}

.blueSection{
  background-color:#002b80;
  color:white;
  padding: 10px;
  box-sizing: border-box;
}

.whiteSection{
  background-color: white;
  color:black;
  width:500px;
  padding: 10px;
  box-sizing: border-box;
  justify-content: center;

}

.arrowContainer{
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrow{
  font-size: 24px;
  color:#000;
  margin-left: 5px;
}

.fontSize{
  font-size: 14px;
}
.cursor{
  cursor: pointer;
}

.bold{
  font-weight: bold;
}

.normal{
  font-weight: normal;
}

.paddingLeft{
  padding-left: 5px;
}

.displayNone{
  display:none;
}

.modal-content {
  background-color: #fefefe !important;
  /*margin: 1% auto; /* 15% from the top and centered */
  padding: 20px;
  left:2%;
  overflow: auto;
  border: 1px solid #888;
  width: 90%; /* Could be more or less, depending on screen size */
  height:90%;
   max-width:100%;
   top:4%;
   border-radius:10px;
 }

 

 .modal1 {
  /* Hidden by default */
 position: fixed; 
 z-index: 9; /* Sit on top */
 left: 0;
 top: 0;
 width: 100%; /* Full width */
 height: 100%; /* Full height */
 overflow: auto; /* Enable scroll if needed */
 background-color: rgb(0,0,0); /* Fallback color */
 background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}


.fixed{
  position:fixed;
}

.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignLeft.MuiTableCell-sizeMedium{
  font-size: 13px;
}

.cell-content{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.cell-content:hover{
  overflow: visible;
  /* max-width: none; */
  /* position: absolute; */
  /* padding: 8px; */
  height:max-content;
  width: max-content;
}

.column {
  float: left;
  height: 10px;
  /* Should be removed. Only for demonstration */
}

.left {
  width: 8%;
  height: 18vh;
  background-color: #D9F6FA;
  /* background-color: #e5e5f7;*/
  margin-top: .5px;
  border-radius: 4px;

}

.right {
  width: 8%;
  height: 18vh;
  background-color: #D9F6FA;
  /* background-color: #e5e5f7;*/
  margin-top: .5px;
  border-radius: 4px;
}

.middle {
  width: 8%;
  height: 18vh;
  background-color: #D9F6FA;
  margin-left: .5%;
  margin-right: .5%;
  margin-top: .5px;
  border-radius: 4px;
}

.leftSpace {
  width: 74%;
}

/* Clear floats after the columns */
.row1 {
  content: "";
  display: table;
  clear: both;
  width: 100%;
}

.contentDiv {
  margin-top: 7%;
}

.contentDivQuestionPop {
  margin-top: 0%
}

.buttonSearch {
  border-radius: 14px;
}

.marginCenter {
  /* margin-left: 10%; */
  text-align:center;
  font-weight:500;
}


.contractImage {
  background-image: url('../src/Img/Icon_02.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40%;
  height: 50%;
}

.mentionImage {
  background-image: url('../src/Img/Icon_01.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40%;
  height: 50%;
  
}

.noMentionImage {
  background-image: url('../src/Img/Icon_03.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40%;
  height: 50%;
  /* background-position-y: top; */
}

.HeadingApp {
  background-image: url("/public/contract_zoom\ _logo.png");
  margin-left: 11%;
  margin-top: 4%;
  background-repeat: no-repeat;
}

.uploadFileCss1{
  border-color: #686868!important;
}

.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputAdornedEnd{
  padding:8.5px 14px;
}

.colorRedFontBold{
  color:red;
  font-weight:bold;
}

.invite{
  margin-left: 90%;
  margin-top:3%;
  cursor: pointer;
}

.sendInvite{
  color:#002b80;
  font-weight:bold;
  font-size:100%;
  margin-left:1%;
  margin-top: 4%;
  cursor: pointer;
}

.outerCircle{
  display:flex;
  margin-left: 71%;
  margin-top: -8%;
  align-items: center;
  justify-content: center;
  width: 13.5%;
  height: 30%;
  background-color: white;
  border-radius: 50%;
}

.innerCircle{
  display:flex;
  align-items: center;
  justify-content: center;
  width: 88%;
  height: 90%;
  background-color: lightblue;
  border-radius: 50%;
}

.outerCircle1{
  display:flex;
  margin-left: 78%;
  margin-top: -5%;
  align-items: center;
  justify-content: center;
  width: 13.5%;
  height: 30%;
  background-color: white;
  border-radius: 50%;
}

.innerCircle1{
  display:flex;
  align-items: center;
  justify-content: center;
  width: 88%;
  height: 90%;
  background-color: #D9F6FA;
  border-radius: 50%;
}

.innerCircle2{
  display:flex;
  align-items: center;
  justify-content: center;
  width: 5%;
  height: 10%;
  background-color: #D9F6FA;
  border-radius: 50%;
}

.height{
  height:90%;
}

.UploadContentDivBlueCircle{
  margin-left: 8.3%;
}

.AcessResultDivBlueCircle{
  margin-left: 46%;
  margin-top: -3%;
}

.icon{
  cursor: pointer;
}

.loader_background{
  top:0%;
  left:0%;
  height:100%;
  width:100%;
  background-color:rgb(0, 0, 0,0.5);
  position:fixed;
  z-index:999;
}